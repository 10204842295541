import React, { Component } from "react"
import { Link } from "gatsby"

import Layout from "../../components/layout"
import SEO from "../../components/seo"

import "../index.css";
import "./about.css";
import "../case-study.css";

import imgAward from "../../images/shuttleid-award-winners.jpg";

import Nav from "../../components/nav"
import AOS from 'aos';
import 'aos/dist/aos.css';
import Contact from "../../components/contact"
import Hero from "../../components/hero-standard"
import Footer from "../../components/footer";

class AboutPage extends Component {

  constructor(props) {
    super(props);

    this.state = {
      activeTab: null
    };
  }

  componentDidMount(){
    AOS.init({
      duration : 2000
    });
  }

  componentDidUpdate() {
    AOS.refresh();
  }

  showTab(tab) {
    this.setState({
      activeTab: this.state.activeTab === tab ? null : tab
    });
  }

  render() {
    return (
      <Layout>
        <SEO title="About us" />
        <Nav activeTab="about" />
        <div>
          <div>

              <Hero h1="About ShuttleID" h2="Award-winning tech that keeps things simple" />

              <div className="about-page article">

                <div data-aos="fade-up" data-aos-duration="800" className='article-inner'>
                  <div className="contact__passengers contact__section">
                    <h2>How it all started</h2>

                    <p>We help transport providers modernise their school, college and work services with simple and affordable technology. Our award-winning platform is at the heart of over 8 million passenger trips each year. We're a family business based in Leeds, Yorkshire.</p>
                    <img src={imgAward} alt="ShuttleID - Award-winning technology" />
                    <Link to="/about/our-story/" className="btn">Read our story</Link>
                  </div>

                  <div className="contact__section">
                    <h2>Our pledge</h2>
                    <p>Our commitment to transport providers:</p>

                    <div className="package-page__faq" style={{ paddingLeft: 0, paddingRight: 0 }}>
                      <div className='package__faq'>

                        <div className={`${this.state.activeTab === 'tab1' ? 'active' : ''} package__faq__item`}>
                          <div className="package__faq__head" onClick={() => { this.showTab('tab1')}}>
                            <span>We make great technology affordable</span>
                            <span className="package__faq__head-symbol">
                              {this.state.activeTab === 'tab1' && (<>-</>)}
                              {this.state.activeTab !== 'tab1' && (<>+</>)}
                            </span>
                          </div>
                          <div className="package__faq__body">
                            <p>You could say it's our Yorkshire heritage, but we have a keen eye for cost-optimisation. We enjoy using the latest technology and innovative thinking to help drive down cost. We're specialists in cloud technology and we know how to use it to commercial advantage. We offer the best value ticket scanner on the market.</p>
                          </div>
                        </div>

                        <div className={`${this.state.activeTab === 'tab2' ? 'active' : ''} package__faq__item`}>
                          <div className="package__faq__head" onClick={() => { this.showTab('tab2')}}>
                            <span>We're not a middleman</span>
                            <span className="package__faq__head-symbol">
                              {this.state.activeTab === 'tab2' && (<>-</>)}
                              {this.state.activeTab !== 'tab2' && (<>+</>)}
                            </span>
                          </div>
                          <div className="package__faq__body">
                            <p>You won't see our logo plastered on your vehicles and we're not here to put a wedge between you and your customers. We don't mark up hardware costs. And if you use our platform to accept online payments, the funds are made direct to you, meaning it's without added delays or fear of supplier insolvency.</p>
                          </div>
                        </div>

                        <div className={`${this.state.activeTab === 'tab3' ? 'active' : ''} package__faq__item`}>
                          <div className="package__faq__head" onClick={() => { this.showTab('tab3')}}>
                            <span>We listen and evolve</span>
                            <span className="package__faq__head-symbol">
                              {this.state.activeTab === 'tab3' && (<>-</>)}
                              {this.state.activeTab !== 'tab3' && (<>+</>)}
                            </span>
                          </div>
                          <div className="package__faq__body">
                            <p>The platform has been built on needs of transport providers from day one and it has constantly evolved as those needs have changed. Feedback we often receive is how refreshing it is to have a partner that actually listens and delivers on promises.</p>
                          </div>
                        </div>

                        <div className={`${this.state.activeTab === 'tab4' ? 'active' : ''} package__faq__item`}>
                          <div className="package__faq__head" onClick={() => { this.showTab('tab4')}}>
                            <span>We want to see you succeed</span>
                            <span className="package__faq__head-symbol">
                              {this.state.activeTab === 'tab4' && (<>-</>)}
                              {this.state.activeTab !== 'tab4' && (<>+</>)}
                            </span>
                          </div>
                          <div className="package__faq__body">
                            <p>We're in this for the long haul. We've worked with transport providers of various sizes over the years, and we like to maintain long-lasting relationships. We thrive on helping transport providers solve problems and we want to help you grow your business and delight your customers with the help of our technology.</p>
                          </div>
                        </div>

                      </div>
                    </div>

                  <p>It's never been as important to know who exactly you're getting into business with. <Link to="/about/our-story/">Read about our story</Link>, get in touch for a chat and we'll gladly help put you in contact with existing customers to hear first-hand about their experiences.</p>

                </div>

              </div>

            </div>
            <Contact />
          </div>
        </div>
        <Footer />
      </Layout>
    );
  }
};

export default AboutPage
